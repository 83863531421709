<template>
	<div class="grid-box">
		<Icon />
		<Nav />
		<!-- <Breadcrumb :levels="breadcrumbLevels" /> -->
		<router-view @changeLevels="changeLevels"/>
	</div>
</template>

<script type="plain/text">
import Nav from "@/components/Nav.vue";
import Icon from "@/components/Icon.vue";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
	components: {
		Nav,
		Icon,
		Breadcrumb
	},
	data: () => ({
		breadcrumbLevels: [
			{ text: "Meu Perfil" }
		]
	}),
	methods: {
		changeLevels(levels) {
			this.breadcrumbLevels = levels;
		}
	}
};
</script>

<style>
</style>
